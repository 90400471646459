.phone {
  :global(.PhoneInputCountry) {
    position: absolute;
    top: 50%;
    left: 10px;
    transform: translateY(-50%);
    width: 34px;
    margin-right: 0px;
  }
  :global(.PhoneInputCountryIcon) {
    box-shadow: none !important;
  }
  :global(.PhoneInputInput) {
    padding-left: 54px;
    padding-right: 2.5rem;
    @apply block w-full rounded-md py-2 border ring-0 sm:text-sm focus-visible:outline-none;
  }

  &.default {
    :global(.PhoneInputInput) {
      @apply pr-3 text-gray-900 border-gray-300 placeholder:text-gray-500 focus:border-cyan-400;
    }
  }
  &.error {
    :global(.PhoneInputInput) {
      @apply text-red-900 border-red-300 placeholder:text-red-300 focus:border-red-500;
    }
  }
  &.phone-small {
    :global(.PhoneInputInput) {
      @apply h-[34px] text-sm pl-14;
    }
  }
}
